<template>
  <div class="evaluate">
    <div class="container">
        <div class="topBanner"><p>欢迎使用</p><strong>中文出版物知识产权评价系统</strong></div>
        <router-view/>
    </div>
      
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.evaluate{margin: 20px auto;}
.topBanner{width: 100%;height: 160px;background: url(../../assets/images/evaluateTopBg.png)no-repeat;background-size: 100% 100%;padding: 30px 70px;}
.topBanner p{font-family: 'AlibabaPuHuiTiM';font-size: 24px;color: #FFFFFF;line-height: 33px;}
.topBanner strong{font-family: 'AlibabaPuHuiTiB';font-size: 36px;color: #FFFFFF;line-height: 50px;}

@media screen and (max-width: 991px){
.topBanner{padding: 40px 20px;}
.topBanner strong{font-size: 26px;white-space: nowrap;}
}
</style>
